import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import placesDataRaw from "../assets/bestPlacesDataFull.json";
import "./BlogRestaurantGuide.css";
import soltImage from "../assets/solt-empty.png";
import soltLogo from "../assets/logo.png";
import soltFoodies from "../assets/solt-foodies-image.png";

interface HeroSection {
  title: string;
  subtitle: string;
  background_image: string;
  location_city: string;
  url_link: string;
  url_link_es?: string;
}

interface ContentLanguage {
  title: string;
  introduction: string;
  sections: Array<{
    heading: string;
    paragraphs?: string[];
    restaurants?: Array<{
      name: string;
      description: string;
      bullets: string[];
    }>;
    spots?: string[];
    treats?: string[];
    items?: string[];
  }>;
  conclusion: string;
}

interface MainContent {
  content_english: ContentLanguage;
  content_spanish: ContentLanguage;
}

interface PlacesData {
  hero_section: HeroSection;
  main_content: MainContent;
}

const BlogPlacesGuide = () => {
  const { lang, city } = useParams<{ lang: string; city: string }>();
  const navigate = useNavigate();

  const placesData = placesDataRaw as PlacesData[];

  const cityData = placesData.find((location) => {
    const matchUrl =
      lang?.toLowerCase() === "es"
        ? location.hero_section.url_link_es
        : location.hero_section.url_link;
    return matchUrl?.toLowerCase() === city?.toLowerCase();
  });

  if (!cityData) {
    return (
      <div className="error-message">
        Error: No data found for the specified city.
      </div>
    );
  }

  const { hero_section, main_content } = cityData;
  const content =
    lang?.toLowerCase() === "es"
      ? main_content.content_spanish
      : main_content.content_english;

  // Get correct URL based on selected language
  const currentUrl =
    lang?.toLowerCase() === "es"
      ? hero_section.url_link_es
      : hero_section.url_link;

  const handleLanguageSwitch = (selectedLang: string) => {
    const newUrl =
      selectedLang === "es" ? hero_section.url_link_es : hero_section.url_link;
    if (newUrl) navigate(`/blog/cities/${selectedLang}/${newUrl}`);
  };

  const shuffledPlaces = placesData
    .filter(
      (place) =>
        place.hero_section.url_link &&
        place.hero_section.title &&
        place.main_content
    )
    .filter((place) => place.hero_section.url_link !== hero_section.url_link)
    .sort(() => 0.5 - Math.random())
    .slice(0, 20)
    .map((place) => {
      const placeContent =
        lang?.toLowerCase() === "es"
          ? place.main_content.content_spanish
          : place.main_content.content_english;
      return {
        title: placeContent?.title || place.hero_section.title || "Untitled",
        url:
          lang?.toLowerCase() === "es"
            ? place.hero_section.url_link_es || "#"
            : place.hero_section.url_link || "#",
      };
    });

  const topRestaurantsSection = content.sections?.find(
    (section) => section.restaurants?.length
  );

  // Generate the restaurant names as a numbered list
  const restaurantNames =
    topRestaurantsSection?.restaurants?.map(
      (restaurant, index) => `${index + 1}. ${restaurant.name}`
    ) || [];

  const pageTitle = `Top ${content.title} in 2025 | Solt`;
  const pageDescription = `${content.title} - ${restaurantNames.join(" · ")}`;
  const canonicalUrl = `https://solt.app/blog/cities/${lang}/${currentUrl}`;
  
  return (
    <div className="blog-restaurant">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <div className="landing-container">
        <div className="top-navigation">
          <div className="nav-content">
            <a href="/" className="logo-link">
              <img src={soltLogo} alt="Solt logo" className="solt-logo" />
            </a>
            <div className="header-buttons">
              <div className="language-switcher">
                <button
                  className={`lang-switch-button ${
                    lang === "en" ? "active" : ""
                  }`}
                  onClick={() => handleLanguageSwitch("en")}
                  aria-label="Switch to English"
                >
                  EN
                </button>
                <button
                  className={`lang-switch-button ${
                    lang === "es" ? "active" : ""
                  }`}
                  onClick={() => handleLanguageSwitch("es")}
                  aria-label="Switch to Spanish"
                >
                  ES
                </button>
              </div>
              <a
                href="https://apps.apple.com/app/solt-the-app-for-foodies/id6450785241"
                target="_blank"
                rel="noopener noreferrer"
                className="ios-app-button-link"
                aria-label="Download the Solt iOS app"
              >
                <button className="join-creator-button">
                  <img
                    src={require("../assets/apple-icon.png")}
                    alt="Apple Icon"
                    className="apple-icon"
                  />
                  Download the app
                </button>
              </a>
            </div>
          </div>
        </div>

        <main className="main-content">
          <section className="hero-section">
            <h1 className="main-title">
              {lang?.toLowerCase() === "es"
                ? main_content.content_spanish.title
                : hero_section.title}
            </h1>
            <p className="main-subtitle">
              {lang?.toLowerCase() === "es"
                ? "Explora los mejores restaurantes y platos que esta ciudad tiene para ofrecer."
                : hero_section.subtitle}
            </p>
            <p className="intro-text">{content.introduction}</p>
            <img
              src={soltFoodies}
              alt={
                lang?.toLowerCase() === "es"
                  ? "Imagen destacada de comida"
                  : hero_section.title
              }
              className="main-image"
            />
          </section>

          <section className="article-content">
            {content.sections.map((section, index) => (
              <div key={index} className="content-section">
                <h3 className="section-heading">{section.heading}</h3>
                {section.paragraphs?.map((para, idx) => (
                  <p key={idx} className="section-paragraph">
                    {para}
                  </p>
                ))}
                {section.restaurants && (
                  <div className="restaurants-list">
                    {section.restaurants.map((restaurant, idx) => (
                      <div key={idx} className="restaurant-card">
                        <h3 className="restaurant-name">{restaurant.name}</h3>
                        <p className="restaurant-description">
                          {restaurant.description}
                        </p>
                        {restaurant.bullets && (
                          <ul className="restaurant-bullets">
                            {restaurant.bullets.map((bullet, bIdx) => (
                              <li key={bIdx} className="bullet-item">
                                {bullet}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {section.spots && (
                  <div className="spots-list">
                    <h4 className="list-title">
                      {lang?.toLowerCase() === "es"
                        ? "Platos Imprescindibles"
                        : "Must-Try Dishes"}
                    </h4>
                    <ul className="spot-items">
                      {section.spots.map((spot, idx) => (
                        <li key={idx} className="spot-item">
                          {spot}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {section.treats && (
                  <div className="treats-list">
                    <h4 className="list-title">
                      {lang?.toLowerCase() === "es"
                        ? "Dulces y Postres"
                        : "Local Treats and Desserts"}
                    </h4>
                    <ul className="treat-items">
                      {section.treats.map((treat, idx) => (
                        <li key={idx} className="treat-item">
                          {treat}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {section.items && (
                  <div className="items-list">
                    <h4 className="list-title">
                      {lang?.toLowerCase() === "es"
                        ? "Otros Destacados"
                        : "Other Highlights"}
                    </h4>
                    <ul className="item-list">
                      {section.items.map((item, idx) => (
                        <li key={idx} className="item">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
            <p className="conclusion-text">{content.conclusion}</p>
          </section>
        </main>

        <div className="newsletter-section">
          <img
            src={soltImage}
            alt={lang?.toLowerCase() === "es" ? "Boletín" : "Newsletter"}
            className="newsletter-image"
          />
          <div className="newsletter-overlay">
            <div className="newsletter-content">
              <h2 className="newsletter-heading">
                {lang?.toLowerCase() === "es"
                  ? "Mira dónde come la gente"
                  : "See where people eat"}
              </h2>
              <p className="newsletter-subtitle">
                {lang?.toLowerCase() === "es"
                  ? "Publica tus propias fotos de comida"
                  : "Post your own food pictures"}
              </p>
              <a
                href="https://apps.apple.com/us/app/solt-see-where-people-eat/id6450785241"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="join-creator-button">
                  {lang?.toLowerCase() === "es" ? "Únete ahora" : "Join Now"}
                </button>
              </a>
            </div>
          </div>
        </div>

        <footer className="footer">
          <h3 className="footer-heading">
            {lang?.toLowerCase() === "es"
              ? "Lugares para comer"
              : "Places to eat"}
          </h3>
          <div className="footer-grid">
            {shuffledPlaces.length ? (
              shuffledPlaces.map((place, index) => (
                <div key={index} className="footer-section">
                  <a
                    href={`/blog/cities/${lang}/${place.url}`}
                    className="footer-link"
                  >
                    {place.title}
                  </a>
                </div>
              ))
            ) : (
              <p className="no-places-message">
                {lang?.toLowerCase() === "es"
                  ? "No se encontraron lugares para mostrar."
                  : "No places found to display."}
              </p>
            )}
          </div>
          <div className="footer-bottom">
            <span>
              © 2024 Solt.{" "}
              {lang?.toLowerCase() === "es"
                ? "Todos los derechos reservados"
                : "All rights reserved"}
            </span>
            <nav className="footer-links">
              <a href="/privacy">
                {lang?.toLowerCase() === "es"
                  ? "Política de Privacidad"
                  : "Privacy Policy"}
              </a>
              <a href="/terms">
                {lang?.toLowerCase() === "es"
                  ? "Términos de uso"
                  : "Terms Of Use"}
              </a>
            </nav>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default BlogPlacesGuide;
