// ScrollToTop.tsx
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollToTopProps {
  enabledPaths?: string[];
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ enabledPaths = [] }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (enabledPaths.some((path) => pathname.startsWith(path))) {
      window.scrollTo(0, 0);
    }
  }, [pathname, enabledPaths]);

  return null;
};

export default ScrollToTop;