import React from "react";
import { useParams, useSearchParams, Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import blogPostData from "../assets/blogPostData.json";
import "./BlogPost.css"; 
import soltLogo from "../assets/logo.png";

const BlogPosts = () => {
  // Extract URL parameters for city and language.
  const { city, lang } = useParams();
  // Get the current page number from query parameters, defaulting to 1.
  const [searchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") || "1", 10);
  const navigate = useNavigate();

  // Find the city data (using a case-insensitive match)
  const cityData = blogPostData.find(
    (c) => c.city.toLowerCase() === city?.toLowerCase()
  );

  if (!cityData) {
    return (
      <div className="blog-post-page">
        <h1>{lang === "es" ? "Ciudad no encontrada" : "City not found"}</h1>
      </div>
    );
  }

  // Determine the target language.
  // For example, 'en' maps to English; anything else is treated as Spanish.
  const targetLang = lang?.toLowerCase() === "en" ? "English" : "Spanish";

  // Filter the groups for this language.
  let groupsForLang = cityData.groups.filter(
    (group) => group.language === targetLang
  );

  // Even if there are no posts for this language, ensure a group exists.
  if (groupsForLang.length === 0) {
    groupsForLang.push({
      language: targetLang,
      url:
        targetLang === "English"
          ? `/what-to-eat-in-${city?.toLowerCase()}-1`
          : `/que-comer-en-${city?.toLowerCase()}-1`,
      posts: [],
    });
  }

  // If the city has multiple groups, use the page number to select which group.
  const groupToDisplay = groupsForLang[page - 1];

  if (!groupToDisplay) {
    return (
      <div className="blog-post-page">
        <h1>
          {lang === "es" 
            ? `No se encontraron publicaciones para la página ${page}` 
            : `No posts found for page ${page}`}
        </h1>
      </div>
    );
  }

  // Use the first post for page metadata if available.
  const firstPost = groupToDisplay.posts[0] || null;
  const pageTitle = firstPost
    ? `${targetLang === "English" ? "Best food in" : "Mejor comida en"} ${firstPost.post.location_city} | Solt`
    : `${targetLang === "English" ? "Best food in" : "Mejor comida en"} ${cityData.city} | Solt`;
  const pageDescription = firstPost
    ? `${targetLang === "English" ? "Best food to eat in" : "Mejor comida para comer en"} ${
        firstPost.post.location_city
      }: ${groupToDisplay.posts
        .map((p, index) => `${index + 1}. ${p.post.title}`)
        .join(" - ")}`
    : `${targetLang === "English" ? "Best food in" : "Mejor comida en"} ${cityData.city}`;
  const canonicalUrl = `https://solt.app/blog/${city}/best-food/${lang}?page=${page}`;

  // Handle language switch
  const handleLanguageSwitch = (selectedLang: string) => {
    const newPath = `/blog/${city}/best-food/${selectedLang}?page=${page}`;
    navigate(newPath);
  };

  return (
    <div className="blog-post-page">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      {/* Top Navigation */}
      <div className="top-navigation">
        <div className="nav-content">
          <a href="/">
            <img src={soltLogo} alt="Solt logo" className="solt-logo" />
          </a>
          <div className="header-buttons">
            <div className="language-switcher">
              <button
                className={`lang-switch-button ${lang === "en" ? "active" : ""}`}
                onClick={() => handleLanguageSwitch("en")}
                aria-label="Switch to English"
              >
                EN
              </button>
              <button
                className={`lang-switch-button ${lang === "es" ? "active" : ""}`}
                onClick={() => handleLanguageSwitch("es")}
                aria-label="Switch to Spanish"
              >
                ES
              </button>
            </div>
            <a
              href="https://apps.apple.com/app/solt-the-app-for-foodies/id6450785241"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Download the app"
            >
              <button className="join-creator-button">
                <img
                  src={require("../assets/apple-icon.png")}
                  alt="Apple Icon"
                  className="apple-icon"
                />
                {targetLang === "English" ? "Download the app" : "Descargar la app"}
              </button>
            </a>
          </div>
        </div>
      </div>

      <h1 className="city-title">
        {targetLang === "English" 
          ? `Best food in ${cityData.city}` 
          : `Mejor comida en ${cityData.city}`}
      </h1>

      {/* Posts Grid */}
      <div className="posts-container">
        {groupToDisplay.posts.length > 0 ? (
          groupToDisplay.posts.map((post) => (
            <div key={post.id} className="post-card">
              <div className="featured-image">
                <img src={post.post.image} alt={post.post.title} />
              </div>

              <div className="post-content">
                <div className="post-header">
                  <h2 className="post-title">{post.post.title}</h2>
                  <div className="cuisine-tag">
                    {post.analysis.ContentSections.Overview.Cuisine}
                  </div>
                  <p className="post-location">{post.post.location}</p>
                  <p className="post-rating">
                    {post.analysis.Header.RatingAndLikes}
                  </p>
                </div>

                <div className="content-sections">
                  <div className="section">
                    <h3 className="section-title">{targetLang === "English" ? "Overview" : "Resumen"}</h3>
                    <p>
                      <strong>{targetLang === "English" ? "Available at:" : "Disponible en:"}</strong>{" "}
                      {post.analysis.ContentSections.Overview.AvailableAt}
                    </p>
                    <p>
                      <strong>{targetLang === "English" ? "Ideal for:" : "Ideal para:"}</strong>{" "}
                      {post.analysis.ContentSections.Overview.IdealFor}
                    </p>
                  </div>

                  <div className="section">
                    <h3 className="section-title">{targetLang === "English" ? "Highlights" : "Destacados"}</h3>
                    <ul className="highlights-list">
                      {post.analysis.ContentSections.DishHighlights.map(
                        (highlight, index) => (
                          <li key={index}>{highlight}</li>
                        )
                      )}
                    </ul>
                  </div>

                  <div className="section">
                    <h3 className="section-title">{targetLang === "English" ? "Reviews" : "Reseñas"}</h3>
                    <div className="reviews-container">
                      {post.analysis.ContentSections.CustomerReviews.UserReviews.map(
                        (review, index) => (
                          <div key={index} className="review-item">
                            <p className="review-text">{review}</p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>{targetLang === "English" ? "No posts available." : "No hay publicaciones disponibles."}</p>
        )}
      </div>

      {/* Pagination (only appears if there's more than one group) */}
      {groupsForLang.length > 1 && (
        <div className="pagination">
          {groupsForLang.map((_, index) => (
            <Link
              key={index}
              to={`/blog/${city}/best-food/${lang}?page=${index + 1}`}
              className={index + 1 === page ? "active" : ""}
            >
              {index + 1}
            </Link>
          ))}
        </div>
      )}

      {/* Footer (displayed once at the bottom) */}
      <footer className="footer">
        <h3 className="footer-heading">{targetLang === "English" ? "Best food" : "Mejor comida"}</h3>
        <div className="footer-grid">
          {blogPostData.map((cityDataItem) => (
            <div key={cityDataItem.city} className="footer-section">
              <h4>{cityDataItem.city}</h4>
              <ul className="footer-list">
                {cityDataItem.groups
                  .filter(
                    (group) =>
                      group.language === (lang === "en" ? "English" : "Spanish")
                  )
                  .map((group) => (
                    <li key={group.url}>
                      <a
                        href={`/blog/${cityDataItem.city}/best-food/${lang}?page=1`}
                        className="footer-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {targetLang === "English" 
                          ? `Best food in ${cityDataItem.city}` 
                          : `Mejor comida en ${cityDataItem.city}`}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="footer-bottom">
          <div>
            {targetLang === "English" 
              ? "© 2024 Solt. All rights reserved" 
              : "© 2024 Solt. Todos los derechos reservados"}
          </div>
          <div className="footer-links">
            <Link to="/privacy">
              {targetLang === "English" ? "Privacy Policy" : "Política de Privacidad"}
            </Link>
            <Link to="/terms">
              {targetLang === "English" ? "Terms Of Use" : "Términos de Uso"}
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BlogPosts;