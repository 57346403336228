import React, { useState, useEffect } from "react";
import { MapPin, Loader, ChevronRight, ChevronLeft, Utensils, Landmark, Coffee } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import soltImage from "../assets/soltBolgPoster.jpeg";
import "./BlogPage.css";
import soltLogo from "../assets/logo.png";
import { City, getCities, getCitiesByFirstLetter, getCityContentUrl } from "../utils/cityUtils";
import blogPostData from "../assets/blogPostData.json";
import restaurantData from "../assets/bestRestaurantsDataFull.json";
import placesData from "../assets/bestPlacesDataFull.json";

const BlogPage: React.FC = () => {
  const [cities, setCities] = useState<City[]>([]);
  const [citiesByLetter, setCitiesByLetter] = useState<Record<string, City[]>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const citiesPerPage = 64;
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    // Get cities from utility functions - get unique cities (no duplicates)
    const allCities = getCities(false);
    const groupedCities = getCitiesByFirstLetter();

    setCities(allCities);
    setCitiesByLetter(groupedCities);
    setIsLoading(false);
  }, []);

  const handleLinkClick = (city: string, type: string) => {
    // Navigate to the appropriate page based on the link type
    const url = getCityContentUrl(city, type);
    navigate(url);
  };

  // Metadata for the blog page.
  const pageTitle = "Explore Cities | Solt Blog - the place for foodies";
  const pageDescription =
    "Discover the best food, places, and restaurants in cities around the world on Solt's blog.";
  const canonicalUrl = "https://solt.app/blog";

  const renderPagination = (totalItems: number, itemsPerPage: number) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    if (totalPages <= 1) return null;

    return (
      <div className="pagination">
        <button
          className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          <ChevronLeft size={16} />
          <span>Previous</span>
        </button>

        <div className="pagination-info">
          <span>Page {currentPage} of {totalPages}</span>
        </div>

        <button
          className={`pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          <span>Next</span>
          <ChevronRight size={16} />
        </button>
      </div>
    );
  };

  // Function to get the appropriate icon based on content type
  const getContentIcon = (contentType: string | undefined) => {
    switch (contentType) {
      case 'food':
        return <Utensils className="city-link-icon" />;
      case 'places':
        return <Landmark className="city-link-icon" />;
      case 'restaurants':
        return <Coffee className="city-link-icon" />;
      default:
        return <MapPin className="city-link-icon" />;
    }
  };

  // Function to get the link text based on content type
  const getContentLinkText = (contentType: string | undefined) => {
    switch (contentType) {
      case 'food':
        return 'Best Food';
      case 'places':
        return 'Best Places';
      case 'restaurants':
        return 'Best Restaurants';
      default:
        return 'Explore';
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <Loader className="w-8 h-8 animate-spin text-blue-600" />
          <p className="text-gray-600">Loading content...</p>
        </div>
      </div>
    );
  }

  // Calculate pagination for cities
  const indexOfLastCity = currentPage * citiesPerPage;
  const indexOfFirstCity = indexOfLastCity - citiesPerPage;
  const currentCities = cities.slice(indexOfFirstCity, indexOfLastCity);

  return (
    <div className="blog-page">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      {/* Top Navigation */}
      <div className="top-navigation">
        <div className="nav-content">
          <a href="/">
            <img src={soltLogo} alt="Solt logo" className="solt-logo" />
          </a>
          <div className="header-buttons">
            <a
              href="https://apps.apple.com/app/solt-the-app-for-foodies/id6450785241"
              target="_blank"
              rel="noopener noreferrer"
              className="ios-app-button-link"
              aria-label="Download the app"
            >
              <button className="join-creator-button">
                <img
                  src={require("../assets/apple-icon.png")}
                  alt="Apple Icon"
                  className="apple-icon"
                />
                Download the app
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <div className="hero">
        <img src={soltImage} alt="Featured" className="hero-image" />
      </div>

      {/* City List Section */}
      <div className="blog-content">
        <div className="city-list-section">
          <h2 className="city-list-title">Cities</h2>

          {/* Updated to ensure 3 cards per row with CSS grid */}
          <div className="city-cards-grid">
            {currentCities.map((city, index) => (
              <div className="city-card" key={`${city.slug}-${index}`}>
                <div className="city-card-content">
                  <h2 className="city-card-title">{city.name}</h2>
                  <div className="city-links">
                    {/* Display links for all available content types */}
                    {city.hasFood && (
                      <button
                        className="city-link"
                        onClick={() => handleLinkClick(city.slug, "food")}
                      >
                        <Utensils className="city-link-icon" />
                        Best Food
                      </button>
                    )}
                    {city.hasPlaces && (
                      <button
                        className="city-link"
                        onClick={() => handleLinkClick(city.slug, "places")}
                      >
                        <Landmark className="city-link-icon" />
                        Best Places
                      </button>
                    )}
                    {city.hasRestaurants && (
                      <button
                        className="city-link"
                        onClick={() => handleLinkClick(city.slug, "restaurants")}
                      >
                        <Coffee className="city-link-icon" />
                        Best Restaurants
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          {renderPagination(cities.length, citiesPerPage)}
        </div>
      </div>

      {/* City Footer */}
      {/*<div className="city-footer">*/}
      {/*  <h3 className="city-footer-heading">Explore Cities by First Letter</h3>*/}
      {/*  */}
      {/*  <div className="city-footer-grid">*/}
      {/*    {Object.entries(citiesByLetter).map(([letter, letterCities]) => (*/}
      {/*      <div className="city-footer-section" key={letter}>*/}
      {/*        <h4>{letter}</h4>*/}
      {/*        <ul className="city-footer-list">*/}
      {/*          {letterCities.map(city => {*/}
      {/*            // Determine the URL for this city*/}
      {/*            let cityUrl = '';*/}
      {/*            if (city.hasFood) cityUrl = getCityContentUrl(city.slug, "food");*/}
      {/*            else if (city.hasRestaurants) cityUrl = getCityContentUrl(city.slug, "restaurants");*/}
      {/*            else if (city.hasPlaces) cityUrl = getCityContentUrl(city.slug, "places");*/}
      {/*            */}
      {/*            return (*/}
      {/*              <li key={city.slug}>*/}
      {/*                <a */}
      {/*                  href={cityUrl}*/}
      {/*                  className="city-footer-link"*/}
      {/*                  onClick={(e) => {*/}
      {/*                    e.preventDefault();*/}
      {/*                    if (city.hasFood) handleLinkClick(city.slug, "food");*/}
      {/*                    else if (city.hasRestaurants) handleLinkClick(city.slug, "restaurants");*/}
      {/*                    else if (city.hasPlaces) handleLinkClick(city.slug, "places");*/}
      {/*                  }}*/}
      {/*                >*/}
      {/*                  {city.name}*/}
      {/*                </a>*/}
      {/*              </li>*/}
      {/*            );*/}
      {/*          })}*/}
      {/*        </ul>*/}
      {/*      </div>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/* Footer with all three categories */}
      <footer className="footer">
        <div className="footer-content">
          {/* <div className="footer-categories"> */}
            {/* Best Food Category */}
            {/* <div className="footer-category">
              <h3 className="footer-category-title">
                <Utensils size={18} style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                Best Food
              </h3>
              <ul className="footer-category-list">
                {blogPostData.slice(0, 12).map((cityData: any) => {
                  const cityUrl = getCityContentUrl(cityData.city, "food");
                  return (
                    <li key={`food-${cityData.city}`}>
                      <a
                        href={cityUrl}
                        className="footer-category-link"
                        onClick={(e) => {
                          e.preventDefault();
                          handleLinkClick(cityData.city, "food");
                        }}
                      >
                        Best food in {cityData.city.charAt(0).toUpperCase() + cityData.city.slice(1)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div> */}

            {/* Best Places Category */}
            {/* <div className="footer-category">
              <h3 className="footer-category-title">
                <Landmark size={18} style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                Best Places
              </h3>
              <ul className="footer-category-list">
                {placesData.slice(0, 12).map((place: any, index) => {
                  const placeUrl = `/blog/cities/en/${place.hero_section.url_link}`;
                  return (
                    <li key={`place-${index}`}>
                      <a
                        href={placeUrl}
                        className="footer-category-link"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(placeUrl);
                        }}
                      >
                        {place.hero_section.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div> */}

            {/* Best Restaurants Category */}
            {/* <div className="footer-category">
              <h3 className="footer-category-title">
                <Coffee size={18} style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                Best Restaurants
              </h3>
              <ul className="footer-category-list">
                {restaurantData.slice(0, 12).map((restaurant: any, index) => {
                  const restaurantUrl = `/blog/restaurants/en/${restaurant.hero_section.url_link}`;
                  return (
                    <li key={`restaurant-${index}`}>
                      <a
                        href={restaurantUrl}
                        className="footer-category-link"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(restaurantUrl);
                        }}
                      >
                        {restaurant.hero_section.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div> */}
          {/* </div> */}

          <div className="footer-bottom">
            <div>© {new Date().getFullYear()} Solt. All rights reserved</div>
            <div className="footer-links">
              <a href="/privacy">Privacy Policy</a>
              <a href="/terms">Terms Of Use</a>
              <a href="/cookies">Cookies</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BlogPage;
