// pages/RedirectToApp.tsx
import { useEffect } from 'react';

const RedirectToApp = () => {
    useEffect(() => {
        window.location.href = 'https://apps.apple.com/app/solt-see-where-people-eat/id6450785241'; // Replace with your actual URL
    }, []);

    return <p>Redirecting to Solt iOS app download...</p>;
};

export default RedirectToApp;
