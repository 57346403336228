import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import SignUp from './pages/SignUp';
import UsernameSetup from './pages/UsernameSetup';
import Profile from './pages/Profile';
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Creators from "./pages/Creators";
import Home from "./pages/Home";
import {HelmetProvider} from "react-helmet-async";
import Restaurant from "./pages/Restaurant";
import Cookies from "./pages/Cookies";
import BlogPosts from "./pages/BlogPost"
import BlogPage from "./pages/BlogPage";
import BlogRestaurantGuide from "./pages/BlogRestaurantGuide";
import BlogPlacesGuide from "./pages/BlogPlacesGuide";
import ScrollToTop from './pages/ScrollToTop';
import RedirectToApp from "./pages/RedirectToApp";

const App: React.FC = () => {
    return (
        <HelmetProvider>
            <ScrollToTop enabledPaths={["/blog", "/blog/:city/best-food/:lang", "/blog/restaurants/:lang/:city", "/blog/cities/:lang/:city"]} />
            <Routes>
                <Route path="/creator/sign-up" element={<SignUp/>}/>
                <Route
                    path="/creator/setup-username" element={<UsernameSetup/>}
                    // element={currentUser ? <UsernameSetup /> : <SignUp />}
                />
                <Route path="/blog" element={<BlogPage/>}/>
                <Route path="/blog/:city/best-food/:lang" element={<BlogPosts />} />
                <Route path="/blog/restaurants/:lang/:city" element={<BlogRestaurantGuide/>}/>
                <Route path="/blog/cities/:lang/:city" element={<BlogPlacesGuide />} />
                <Route path="/privacy" element={<Privacy/>}/>
                <Route path="/cookies" element={<Cookies/>}/>
                <Route path="/terms" element={<Terms/>}/>
                <Route path="/creators" element={<Creators/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="/:username" element={<Profile/>}/>
                <Route path="/restaurant/:link" element={<Restaurant/>}/>
                <Route path="/download-app" element={<RedirectToApp />} />

                {/* Wildcard route: redirects any undefined route to Home Page */}
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </HelmetProvider>
    );
};

export default App;
