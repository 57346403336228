import axios, {AxiosResponse} from 'axios';
import {Post} from "./pages/Profile";

const production = true;
const SERVER_URL = production ? 'https://solt-creators-435312.ew.r.appspot.com/api' : 'http://localhost:8080/api';

const api = axios.create({
    baseURL: SERVER_URL
});

// Function to call the `getFormattedAddress` endpoint
export const getFormattedAddress = async (address: string): Promise<string | 'error'> => {
    try {
        // Call the backend endpoint with the address in the request body
        const response: AxiosResponse = await api.post('/creator/parse-address', { address });

        // Check if the response is successful and contains the expected data
        if (response.status === 200 && response.data && response.data.output) {
            return response.data.output; // Return the formatted address
        }

        // Log unexpected response format
        console.error('Unexpected response format:', response);
        return 'error'; // Fallback error if the response is not as expected
    } catch (error) {
        // Handle Axios-specific errors
        if (axios.isAxiosError(error) && error.response) {
            const status = error.response.status;
            const message = error.response.data.message || 'Unknown server error';

            console.error(`Server error (${status}) while formatting address:`, message);
        } else {
            // Handle other potential errors, such as network issues
            console.error('Network or unknown error while formatting address:', error);
        }
        return 'error'; // Generic error
    }
};

// Fetch posts with creator details based on a search query
export const searchPosts = async (searchQuery: string): Promise<any[] | 'error' | 'server-error'> => {
    try {
        // Call the API with the search query
        const response: AxiosResponse = await api.get('/home/search-posts', {
            params: {searchQuery} // Pass the search query as a query parameter
        });

        if (response.status === 200 && Array.isArray(response.data)) {
            return response.data; // Return the filtered posts
        }

        console.error('Unexpected response format:', response);
        return 'error'; // Fallback error if the response is not what we expect
    } catch (error) {
        // Handle Axios-specific errors
        if (axios.isAxiosError(error) && error.response) {
            const status = error.response.status;
            const message = error.response.data.message || 'Unknown server error';

            if (status >= 500) {
                console.error('Server error while searching posts:', message);
                return 'server-error'; // Server-side error
            }
        }

        // Handle other potential errors, including network issues
        console.error('Network or unknown error while searching posts:', error);
        return 'error'; // Return generic error
    }
};

// Fetch all posts with creator details and support pagination
export const getAllPostsWithCreatorDetails = async (
    page: number = 1,
    limit: number = 20
): Promise<{
    posts: any[];
    totalPosts: number;
    currentPage: number;
    totalPages: number;
} | 'error' | 'server-error'> => {
    try {
        // Call the API to get all posts with creator details, passing page and limit as query parameters
        const response: AxiosResponse = await api.get('/home/posts-with-creator-details', {
            params: { page, limit }
        });

        if (response.status === 200 && response.data) {
            // Check if the response data has the expected properties
            const { posts, totalPosts, currentPage, totalPages } = response.data;
            return { posts, totalPosts, currentPage, totalPages };  // Return the paginated data
        }

        console.error('Unexpected response format:', response);
        return 'error';  // Fallback error if the response is not what we expect
    } catch (error) {
        // Handle Axios-specific errors
        if (axios.isAxiosError(error) && error.response) {
            const status = error.response.status;
            const message = error.response.data.message || 'Unknown server error';

            if (status >= 500) {
                console.error('Server error while fetching posts:', message);
                return 'server-error';  // Server-side error
            }
        }

        // Handle other potential errors, including network issues
        console.error('Network or unknown error while fetching all posts:', error);
        return 'error';  // Return generic error
    }
};

// Fetch creator by username
export const getCreatorByUsername = async (username: string): Promise<any | 'error' | 'not-found'> => {
    console.log('getCreatorByUsername');
    try {
        const response = await api.get(`/creator/profile/${username}`); // Use the correct route
        return response.data; // Return the creator data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                console.error('Invalid request: Username parameter is required.');
                return 'error'; // Return 'error' for bad request
            } else if (error.response.status === 404) {
                console.error('Creator not found:', error.response.data.message);
                return 'not-found'; // Return 'not-found' if the creator does not exist
            } else if (error.response.status >= 500) {
                console.error('Server error:', error.response.data.message);
                return 'error'; // Return 'error' for server-side issues
            }
        }
        console.error('Network or unknown error fetching creator:', error);
        return 'error'; // Return 'error' for network or other unknown issues
    }
};

// Fetch username by Firebase UID
export const getUsernameByFirebaseUID = async (firebaseUID: string): Promise<string | 'error' | 'not-found'> => {
    try {
        const response: AxiosResponse = await api.get(`/creator/username/firebaseUID/${firebaseUID}`);

        // Check if the response status is 200 and if it contains the username data
        if (response.status === 200 && response.data && response.data.username) {
            return response.data.username; // Return the username from the response
        }
        return 'error'; // If response format is unexpected, return 'error'
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const {status, data} = error.response;

            if (status === 404 && data.message === 'Creator not found') {
                return 'not-found'; // Return 'not-found' if creator does not exist
            } else if (status >= 500) {
                console.error('Server error while fetching username:', data.message);
                return 'error'; // Server-side error
            }
        }
        // Handle network or unknown errors
        console.error('Network or unknown error fetching username by Firebase UID:', error);
        return 'error'; // Return 'error' for network or other unknown issues
    }
};

// Update creator profile
export const updateCreator = async (
    email: string,
    updateData: {
        name?: string;
        bio?: string;
        postsTitle?: string;
        profileImage?: string;
    }
): Promise<boolean | 'error' | 'not-found'> => {
    try {
        // Include the email in the body of the request, along with other update fields
        const response = await api.put(`/creator/profile`, {
            email, // Send email in the request body as the server expects
            ...updateData, // Other fields to be updated
        });

        if (response.status === 200) {
            return true;  // Profile updated successfully
        }
        return 'error';  // Fallback error
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) {
                return 'not-found';  // Creator not found
            } else if (error.response.status >= 500) {
                console.error('Server error while updating creator profile:', error.response.data.message);
                return 'error';  // Server error
            }
        }
        console.error('Network or unknown error updating creator profile:', error);
        return 'error';  // Generic network or unknown error
    }
};

// Set profile picture for creator using creatorUID
export const setProfilePicture = async (file: File, creatorId: string): Promise<string | 'invalid-file' | 'upload-error'> => {
    const formData = new FormData();
    formData.append('file', file);  // Attach the file to the request

    try {
        const response = await api.post('/creator/profile/image/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                creatorId,  // Pass the creator's Firebase UID (creatorUID) as a query parameter
            },
        });

        if (response.status === 200 && response.data.imageUrl) {
            return response.data.imageUrl;  // Return the uploaded image URL
        }
        return 'upload-error';  // If something unexpected happened
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                console.error('Invalid file format or bad request:', error.response.data.message);
                return 'invalid-file';  // Return specific error for invalid file format
            } else if (error.response.status >= 500) {
                console.error('Server error during file upload:', error.response.data.message);
                return 'upload-error';  // Return specific error for server issues
            }
        }
        console.error('Network or unknown error uploading profile picture:', error);
        return 'upload-error';  // Return generic error for network or unknown issues
    }
};


export const checkCreatorExists = async (email: string): Promise<boolean | 'error' | 'creator-not-found'> => {
    try {
        const response = await api.get(`/creator/${email}`);
        return response.status === 200; // Returns true if the creator exists
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                console.error('Invalid request: Email parameter is required.');
                return 'error'; // Return 'error' for bad request
            } else if (error.response.status === 404) {
                console.error('Creator not found:', error.response.data.message);
                return 'creator-not-found'; // Return specific value when the creator is not found
            } else if (error.response.status >= 500) {
                console.error('Server error:', error.response.data.message);
                return 'error'; // Return 'error' for server-side issues
            }
        }
        console.error('Network or unknown error checking if creator exists:', error);
        return 'error'; // Return 'error' for network or other unknown issues
    }
};


export const createCreator = async (email: string, name: string, firebaseUID: string): Promise<boolean | 'error' | 'conflict'> => {
    try {
        const response = await api.post('/creator', {
            email,
            name,
            firebaseUID,
            privacyPolicyAccepted: true,
            termsAccepted: true,
        });

        return response.status === 201; // Return true if the creator was successfully created
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                console.error('Invalid request: Missing required fields.');
                return 'error'; // Return 'error' for bad request
            } else if (error.response.status === 409) {
                console.error('Conflict: Creator with this email already exists.');
                return 'conflict'; // Return specific value when there's a conflict (duplicate creator)
            } else if (error.response.status >= 500) {
                console.error('Server error:', error.response.data.message);
                return 'error'; // Return 'error' for server-side issues
            }
        }
        console.error('Network or unknown error creating creator:', error);
        return 'error'; // Return 'error' for network or other unknown issues
    }
};


export const checkUsernameExists = async (email: string): Promise<string | null | 'error' | 'creator-not-found'> => {
    try {
        const response = await api.get(`/creator/username/${email}`);
        return response.data?.username || null; // Return the username if it exists
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                console.error('Invalid request: Email parameter is required.');
                return 'error'; // Return 'error' for bad request
            } else if (error.response.status === 404 && error.response.data.message === 'Creator not found') {
                console.error('Creator not found:', error.response.data.message);
                return 'creator-not-found'; // Return specific value when the creator is not found
            } else if (error.response.status === 404 && error.response.data.message === 'Username not found') {
                return null; // Return null if the username does not exist
            } else if (error.response.status >= 500) {
                console.error('Server error:', error.response.data.message);
                return 'error'; // Return 'error' for server-side issues
            }
        }
        console.error('Network or unknown error checking if username exists:', error);
        return 'error'; // Return 'error' for network or other unknown issues
    }
};

export const getPostsByUsername = async (username: string): Promise<any[] | 'error' | 'not-found' | 'missing-username'> => {
    try {
        const response = await api.get(`/creator/posts/${username}`);
        return response.data.posts || []; // Return the list of posts or an empty array
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400 && error.response.data.message === 'Username is required') {
                console.error('Error: Username is required.');
                return 'missing-username'; // Return 'missing-username' if the username was missing
            } else if (error.response.status === 404) {
                console.error('Posts not found for this username.');
                return 'not-found'; // Return specific value when no posts are found
            } else if (error.response.status >= 500) {
                console.error('Server error while fetching posts:', error.response.data.message);
                return 'error'; // Return 'error' for server-side issues
            }
        }
        console.error('Network or unknown error fetching posts:', error);
        return 'error'; // Return 'error' for network or other unknown issues
    }
};

// Add a new post by username
export const addPost = async (
    username: string,
    post: {
        title: string;
        description: string;
        images: string[];
        location: string;
        google_place_id?: string;
        location_english_formatted?: string;
        ratings?: {
            dishRating?: number;
            overallExperienceRating?: number;
        }
    }
): Promise<boolean | 'error' | 'missing-fields' | 'not-found'> => {
    try {
        const response = await api.post(`/creator/posts/${username}`, post);

        if (response.status === 201) {
            return true;
        }
        return 'error'; // Fallback error
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const {status, data} = error.response;
            if (status === 400 && data.message === 'Missing required fields: title, location, images, and ratings (dishRating and overallExperienceRating)') {
                return 'missing-fields';
            } else if (status === 404 && data.message === 'Creator not found') {
                return 'not-found';
            }
        }
        return 'error'; // Fallback error
    }
};

// Update an existing post by username and postId
export const updatePost = async (
    username: string,
    postId: string,
    post: Post
): Promise<boolean | 'error' | 'missing-fields' | 'not-found' | 'post-not-found'> => {
    try {
        const response = await api.put(`/creator/posts/${username}/${postId}`, post);

        if (response.status === 200) {
            return true;
        }
        return 'error'; // Fallback error
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const {status, data} = error.response;
            if (status === 400 && data.message === 'Missing required fields: title, location, images, and ratings (dishRating and overallExperienceRating)') {
                return 'missing-fields';
            } else if (status === 404 && data.message === 'Creator not found') {
                return 'not-found';
            } else if (status === 404 && data.message === 'Post not found') {
                return 'post-not-found';
            }
        }
        return 'error'; // Fallback error
    }
};

export const uploadImage = async (file: File, creatorId: string): Promise<string | 'invalid-file' | 'upload-error'> => {
    const formData = new FormData();
    formData.append('file', file); // Only append the file
    console.log('uploadImage');

    try {
        const response = await api.post('/creator/posts/image/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                creatorId,  // Pass the creator-id as a query parameter
            },
        });

        // Check for a successful response and a valid URL
        if (response.status === 200 && response.data && response.data.url) {
            return response.data.url;
        }

        // Handle unexpected server responses
        console.error('Unexpected server response:', response);
        return 'upload-error'; // In case of unexpected server response
    } catch (error: any) {
        if (error.response) {
            // Server responded with an error status code
            const status = error.response.status;

            if (status === 400) {
                console.error('Invalid file format or bad request:', error.response.data.message);
                return 'invalid-file'; // Return specific error for invalid file
            } else if (status === 500) {
                console.error('Server error during file upload:', error.response.data.message);
                return 'upload-error'; // Return specific error for server issues
            }
        } else if (error.request) {
            // Request was made but no response was received
            console.error('No response from server:', error.request);
            return 'upload-error'; // Handle network or timeout issues
        } else {
            // Something else happened while setting up the request
            console.error('Error in uploadImage function:', error.message);
            return 'upload-error';
        }
    }

    // Return a fallback in case something goes wrong unexpectedly
    return 'upload-error';
};

// Increment or decrement the reaction count for a post
export const updateReactionCount = async (
    username: string,
    postId: string,
    increment: boolean
): Promise<boolean | 'error' | 'post-not-found' | 'creator-not-found'> => {
    try {
        // Send the increment boolean to the backend
        const response = await api.put(`/creator/posts/${username}/${postId}/reaction`, {increment});

        // Check if the response indicates success
        if (response.status === 200) {
            return true; // Successfully updated the reaction count
        }

        // Fallback error if status is not 200
        return 'error';
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const {status, data} = error.response;

            if (status === 404 && (data.message === 'Post not found' || data.message === 'Creator posts not found')) {
                return 'post-not-found';
            } else if (status === 404 && data.message === 'Creator not found') {
                return 'creator-not-found';
            }
        }

        // Fallback for unknown errors
        console.error('Error updating reaction count:', error);
        return 'error';
    }
};

// Fetch posts by link
export const getPostsByLink = async (link: string): Promise<any[] | 'error' | 'not-found' | 'missing-link'> => {
    try {
        if (!link) {
            console.error('Error: Link parameter is required.');
            return 'missing-link'; // Handle case where link is not provided
        }

        // Make a GET request to the posts-by-link endpoint
        const response = await api.get(`/restaurant/posts-by-link/${encodeURIComponent(link)}`);

        // Check if the response is successful and contains the posts
        if (response.status === 200 && Array.isArray(response.data)) {
            return response.data; // Return the posts
        }

        // Log unexpected response format
        console.error('Unexpected response format:', response);
        return 'error'; // Fallback error
    } catch (error) {
        // Handle Axios-specific errors
        if (axios.isAxiosError(error) && error.response) {
            const { status, data } = error.response;

            if (status === 400) {
                console.error('Invalid request: Link parameter is required.');
                return 'missing-link'; // Return specific value for missing or invalid link
            } else if (status === 404) {
                console.error('No posts found for this link:', data.message);
                return 'not-found'; // Return specific value when no posts are found
            } else if (status >= 500) {
                console.error('Server error while fetching posts by link:', data.message);
                return 'error'; // Return error for server-side issues
            }
        }

        // Handle network or unknown errors
        console.error('Network or unknown error while fetching posts by link:', error);
        return 'error'; // Return generic error
    }
};


// Update Privacy and Terms
export const updatePrivacyTerms = async (
    email: string,
    firebaseUID: string,
    privacyPolicyDetails?: {
        dateAccepted: Date;
        accepted: boolean;
    },
    termsDetails?: {
        dateAccepted: Date;
        accepted: boolean;
    },
    cookiesDetails?: {
        dateAccepted: Date;
        accepted: boolean;
    }
): Promise<'success' | 'error' | 'creator-not-found' | 'invalid-request'> => {
    try {
        // Construct the payload for the API request
        const payload = {
            email,
            firebaseUID,
            privacyPolicyDetails,
            termsDetails,
            cookiesDetails
        };

        // Call the backend API
        const response: AxiosResponse = await api.put('/creator/privacy-terms', payload);

        // Check if the update was successful
        if (response.status === 200) {
            console.log('Privacy and terms updated successfully.');
            return 'success';
        }

        console.error('Unexpected response:', response);
        return 'error'; // Handle unexpected success response
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const { status, data } = error.response;

            if (status === 400) {
                console.error('Invalid request: Missing or invalid fields.', data.message);
                return 'invalid-request';
            } else if (status === 404 && data.message === 'Creator not found') {
                console.error('Creator not found:', data.message);
                return 'creator-not-found';
            } else if (status >= 500) {
                console.error('Server error:', data.message);
                return 'error';
            }
        }

        // Handle network or other unknown errors
        console.error('Network or unknown error updating privacy and terms:', error);
        return 'error';
    }
};


// Fetch a place by link
export const getPlaceByLink = async (link: string): Promise<any | 'error' | 'not-found'> => {
    try {
        if (!link) {
            console.error('Error: Link parameter is required.');
            return 'error'; // Handle case where link is not provided
        }

        // Make a GET request to the place-by-link endpoint
        const response = await api.get(`/restaurant/place/link/${encodeURIComponent(link)}`);

        // Check if the response is successful and contains the place data
        if (response.status === 200 && response.data) {
            return response.data; // Return the place data
        }

        // Log unexpected response format
        console.error('Unexpected response format:', response);
        return 'error'; // Fallback error
    } catch (error) {
        // Handle Axios-specific errors
        if (axios.isAxiosError(error) && error.response) {
            const { status, data } = error.response;

            if (status === 404) {
                console.error('Place not found for this link:', data.message);
                return 'not-found'; // Return specific value when place is not found
            } else if (status >= 500) {
                console.error('Server error while fetching place by link:', data.message);
                return 'error'; // Return error for server-side issues
            }
        }

        // Handle network or unknown errors
        console.error('Network or unknown error while fetching place by link:', error);
        return 'error'; // Return generic error
    }
};

export default api;
