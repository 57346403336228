import blogPostData from '../assets/blogPostData.json';
import restaurantData from '../assets/bestRestaurantsDataFull.json';
import placesData from '../assets/bestPlacesDataFull.json';

export interface City {
  name: string;
  slug: string;
  hasFood: boolean;
  hasPlaces: boolean;
  hasRestaurants: boolean;
  contentType?: string; // Primary content type for this card
}

/**
 * Extracts a list of cities from all data sources
 * @param expandMultipleContent If true, cities with multiple content types will appear multiple times
 * @returns An array of city objects with name, slug, and content availability
 */
export const getCities = (expandMultipleContent: boolean = false): City[] => {
  const cities: Record<string, City> = {};
  
  // Extract cities from blog post data (food)
  blogPostData.forEach((cityData: any) => {
    const citySlug = cityData.city.toLowerCase();
    const cityName = citySlug.charAt(0).toUpperCase() + citySlug.slice(1);
    
    if (!cities[citySlug]) {
      cities[citySlug] = {
        name: cityName,
        slug: citySlug,
        hasFood: false,
        hasPlaces: false,
        hasRestaurants: false
      };
    }
    
    cities[citySlug].hasFood = true;
  });
  
  // Extract cities from restaurant data
  restaurantData.forEach((restaurant: any) => {
    const cityName = restaurant.hero_section.location_city;
    const citySlug = cityName.toLowerCase().replace(/\s+/g, '-');
    
    if (!cities[citySlug]) {
      cities[citySlug] = {
        name: cityName,
        slug: citySlug,
        hasFood: false,
        hasPlaces: false,
        hasRestaurants: false
      };
    }
    
    cities[citySlug].hasRestaurants = true;
  });
  
  // Extract cities from places data
  placesData.forEach((place: any) => {
    const cityName = place.hero_section.location_city;
    const citySlug = cityName.toLowerCase().replace(/\s+/g, '-');
    
    if (!cities[citySlug]) {
      cities[citySlug] = {
        name: cityName,
        slug: citySlug,
        hasFood: false,
        hasPlaces: false,
        hasRestaurants: false
      };
    }
    
    cities[citySlug].hasPlaces = true;
  });
  
  // Convert to array
  let cityArray = Object.values(cities);
  
  // If expandMultipleContent is true, create multiple entries for cities with multiple content types
  if (expandMultipleContent) {
    const expandedCities: City[] = [];
    
    cityArray.forEach(city => {
      const contentTypes = [];
      if (city.hasFood) contentTypes.push('food');
      if (city.hasPlaces) contentTypes.push('places');
      if (city.hasRestaurants) contentTypes.push('restaurants');
      
      // If city has only one content type, add it as is
      if (contentTypes.length === 1) {
        expandedCities.push({
          ...city,
          contentType: contentTypes[0]
        });
      } else {
        // If city has multiple content types, create a separate entry for each
        contentTypes.forEach(type => {
          expandedCities.push({
            ...city,
            contentType: type
          });
        });
      }
    });
    
    cityArray = expandedCities;
  }
  
  // Sort alphabetically by name
  return cityArray.sort((a, b) => a.name.localeCompare(b.name));
};

/**
 * Groups cities by first letter
 * @returns An object with first letters as keys and arrays of cities as values
 */
export const getCitiesByFirstLetter = (): Record<string, City[]> => {
  const cities = getCities(false); // Don't expand for the footer
  const grouped: Record<string, City[]> = {};
  
  cities.forEach(city => {
    const firstLetter = city.name.charAt(0).toUpperCase();
    if (!grouped[firstLetter]) {
      grouped[firstLetter] = [];
    }
    grouped[firstLetter].push(city);
  });
  
  return grouped;
};

/**
 * Gets the URL for a specific city and content type
 * @param city The city slug
 * @param type The content type (food, places, restaurants)
 * @returns The URL for the specified content
 */
export const getCityContentUrl = (city: string, type: string): string => {
  // Find the specific URL for restaurants and places from the data
  let url = '';
  
  switch (type) {
    case "food":
      return `/blog/${city}/best-food/en`;
      
    case "places":
      // Find the specific place URL for this city
      const place = placesData.find(p => 
        p.hero_section.location_city.toLowerCase() === city.replace(/-/g, ' ') ||
        p.hero_section.location_city.toLowerCase().replace(/\s+/g, '-') === city
      );
      
      if (place) {
        url = `/blog/cities/en/${place.hero_section.url_link}`;
      } else {
        url = `/blog/cities/en/best-places-to-eat-in-${city}`;
      }
      return url;
      
    case "restaurants":
      // Find the specific restaurant URL for this city
      const restaurant = restaurantData.find(r => 
        r.hero_section.location_city.toLowerCase() === city.replace(/-/g, ' ') ||
        r.hero_section.location_city.toLowerCase().replace(/\s+/g, '-') === city
      );
      
      if (restaurant) {
        url = `/blog/restaurants/en/${restaurant.hero_section.url_link}`;
      } else {
        url = `/blog/restaurants/en/best-restaurants-to-eat-in-${city}`;
      }
      return url;
      
    default:
      return `/blog/${city}`;
  }
}; 